export default function CountryBadge({ className, width = 'w-7', country }) {
	if (country.name === '' || !country) return;

	return (
		<div
			className={`rounded-full ring ring-1 ring-base-100 overflow-hidden ${width} ${className}`}
		>
			<img src={country.image} />
		</div>
	);
}
