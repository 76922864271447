import CountryBadge from './CountryBadge';

export default function Avatar({ className, width = 'w-24', url = '/images/default/profileAvatar.png', country = null, ...props }) {
	return (
		<div className={`avatar relative ${className}`} {...props}>
			{country && (
				<CountryBadge country={country} className="absolute bottom-0 right-0 w-[40%]" />
			)}
			<div className={`${width} rounded-full ring ring-1 ring-base-100 `}>
				<img src={url} alt="preview" style={{ objectFit: 'cover' }} />
			</div>
		</div>
	);
}
